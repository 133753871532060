import React from 'react'

export default () => (
  <div>
    <p />
    <div id="WEBzona" style={{display: 'none'}}>
      <h1><strong>Fundacja SaSa – organizacja charytatywna świadcząca pomoc humanitarną i wolontariat medyczny &amp; Sprzęt medyczny dla Afryki:</strong></h1>
      <ul>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>wolontariat medyczny</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>opieka medyczna w Afryce</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>wolontariat medyczny za granicą</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>wolontariat medyczny dla studentów medycyny</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>wsparcie darczyńców</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>crowdfunding</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>szlachetna paczka</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>pomoc dla Afryki</span></strong></span></h4>
        </li>
      </ul>
      <h3><span style={{fontSize: '14pt'}}><strong>Paczki w ramach crowdfundingu:</strong></span></h3>
      <ul>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>paczka dla ubogich</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>paczka dla chorych</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>paczka dla potrzebujących</span></strong></span></h4>
        </li>
      </ul>
      <h3><span style={{fontSize: '14pt'}}><strong>Dostarczamy sprzęt medyczny:</strong></span></h3>
      <ul>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}>Sprzęt medyczny dla chorych</span></span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}>Sprzęt medyczny dla potrzebujących</span></span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}>Sprzęt medyczny dla szpitali w Afryce</span></span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}>Sprzęt medyczny dla szpitali na świecie</span></span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline'}}><span style={{color: '#ff0000', textDecoration: 'underline'}}>Sprzęt medyczny dla szpitali</span></span></strong></span></h4>
        </li>
      </ul>
      <h3><span style={{fontSize: '14pt'}}><strong>Świadczymy pomoc lekarską:</strong></span></h3>
      <ul>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Pomoc humanitarna</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Pomoc medyczna na terenie klęsk żywiołowych</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Katastrofa humanitarna</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Opieka medyczna w Afryce</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>crowdfunding medyczny</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>crowdfunding humanitarny</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Misja medyczna w Afryce</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Misja medyczna</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Pomoc dla szpitali w Afryce</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Pomoc dla potrzebujących szpitali</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Lekarze w Afryce</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Lekarze pomagający potrzebującym</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Lekarze pomagający chorym</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Lekarze pracujący dla potrzebujących</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Lekarze pracujący dla chorych</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Wsparcie misji medycznych</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Wsparcie wolontariatu medycznego</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Wsparcie pomocy dla szpitalu</span></strong></span></h4>
        </li>
        <li>
          <h4><span style={{fontSize: '14pt'}}><strong><span style={{textDecoration: 'underline', color: '#ff0000'}}>Wsparcie pomocy szpitalom w Afryce</span></strong></span></h4>
        </li>
      </ul>
      <h2><strong>Organizacja pożytku publicznego (organizacja OPP) – Fundacja SaSa</strong></h2>
      <p>Nasza Fundacja SaSa jako Organizacja pożytku publicznego (organizacja OPP) kieruje swoje działania w stronę pomocy humanitarnej i pomocy charytatywnej, w celu organizujemy:</p>
      <ul>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>akcje charytatywne</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>organizacje charytatywne</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>pomoc humanitarna</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>wolontariat w afryce</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>wolontariat afryka</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>1 podatku dla dzieci</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>1 procent podatku lista organizacji</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>organizacje humanitarne</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>humanitarna pomoc</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>wolontariat misyjny</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>komu przekazać 1 podatku</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>wolontariat medyczny</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>organizacja humanitarna</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>akcje humanitarne</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>organizacje pomagające ludziom</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>na co 1 podatku</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>komu 1 podatku</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>komu 1 podatku</strong></span></em></span></h3>
        </li>
        <li>
          <h3><span style={{color: '#003300'}}><em><span style={{fontSize: '10pt'}}><strong>afryka wolontariat</strong></span></em></span></h3>
        </li>
      </ul>
      <h2>Dodatkowe informacje odnośnie naszej Fundacji charytatywnej SaSa:</h2>
      <table>
        <tbody>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>crowdfunding medyczny</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>opieka medyczna w afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka dla chorych w afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka dla potrzebujących</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka dla ubogich</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat medyczny</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wsparcie misji medycznych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>crowdfunding humanitarny</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w hospicjum</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>humanitarna pomoc</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje pożytku publicznego</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>hospicjum wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>hospicjum dla dzieci</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne w polsce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc humanitarna organizacje</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla biednych rodzin</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polskie organizacje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje charytatywne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje pomocy humanitarnej</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat za granicą</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacja humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc humanitarna w polsce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat zagraniczny</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowa pomoc humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>misja humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska akcja humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje charytatywne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcja charytatywna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polskie akcje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne przykłady</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat medyczny za granicą</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>bezinteresowna pomoc</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc humanitarna dla afryki</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacja non profit</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>centrum wolontariatu</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat afryka</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat bydgoszcz</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka dla biednych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>instytut reumatologii</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>misje humanitarne wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcja humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje humanitarne w afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc humanitarna dla ukrainy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc charytatywna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki swiateczne dla ubogich</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>miedzynarodowa pomoc humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacja charytatywna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>aukcje charytatywne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla potrzebujących rodzin</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska misja humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje charytatywne w polsce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne działające w polsce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc humanitarna ue</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje niosące pomoc humanitarną</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>cele charytatywne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne w afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat na wakacje</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc innym</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>chcę zostać wolontariuszem</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat misyjny</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla biednych dzieci</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc potrzebującym</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla dzieci z biednych rodzin</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla rodzin ubogich</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat bielsko</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje międzynarodowe niosące pomoc humanitarną</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla biednych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>hospicjum dla osób starszych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje międzynarodowe pomoc humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla potrzebujacych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc dla afryki</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc biednym</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat dla młodzieży</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pozarządowe organizacje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>największe polskie organizacje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>zostań wolontariuszem</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoce humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat międzynarodowy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla rodzin wielodzietnych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc dla biednych dzieci</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>caritas wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>misje humanitarne w afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka dla biednych rodzin</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne w polsce przykłady</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc na swieta dla ubogich</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>regionalne centrum wolontariatu</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat bytom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje pomagające ludziom w polsce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje niosące pomoc potrzebującym</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polskie organizacje charytatywne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>afryka wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>praca wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat we włoszech</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat bielsko biała</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowa organizacja humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka dla ubogiej rodziny</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje zajmujące się pomocą humanitarną</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne międzynarodowe</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>zrób paczkę dla biednych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>cele wolontariatu</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat australia</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>misje wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat azja</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat ameryka południowa</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje międzynarodowe niosące pomoc</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki dla dzieci z ubogich rodzin</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w azji</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc dla biednych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat indie</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc biednym dzieciom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska pomoc humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w indiach</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w ameryce południowej</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomocna paczka</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w australii</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc innym ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne i ich działalność</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat nepal</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje charytatywne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>misja w afryce wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat praca</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat dla młodzieży za granicą</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>humanitarne organizacje pozarządowe</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc biednym rodzinom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>centrum wolontariatu w warszawie</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje charytatywne warszawa</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w hiszpanii</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka żywnościowa dla ubogich</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w europie</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>cechy dobrego wolontariusza</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat ukraina</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat młodzieżowy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>hospicjum dla chorych na raka</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka pomoc</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>misje do afryki</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje wolontariatu</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje humanitarne pozarządowe</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka dla ubogich dzieci</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>krs pomoc</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc biednym ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>cechy wolontariatu</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat japonia</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w japonii</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat wakacje</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>lista rodzin potrzebujących pomocy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje charytatywne warszawa</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje charytatywne polska</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wyjazd do afryki wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>misje afryka wolontariat</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc charytatywna paczka</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w anglii</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczka na swieta dla potrzebujacych</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat na ukrainie</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat we francji</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>niesienie pomocy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje niosące pomoc ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc ludziom potrzebującym</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje charytatywne różne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc biednym dzieciom w polsce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>wolontariat w rosji</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc ubogim dzieciom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc humanitarna uw</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc charytatywna warszawa</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska organizacja humanitarna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje niosące pomoc</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polskie akcje charytatywne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje wolontariackie w polsce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc charytatywna kraków</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje międzynarodowe pomagające ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje pomocy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc biednym dzieciom w afryce</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje rządowe</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska akcja humanitarna cele</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje pomocy ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje pomocy ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje zajmujące się udzielaniem pomocy ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska organizacja charytatywna</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje pomagające ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje pomocy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>paczki caritas</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska akcja humanitarna czym się zajmuje</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje pomagające ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje międzynarodowe zajmujące się udzielaniem pomocy ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje pomocy dla biednych państw azji i afryki</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>międzynarodowe organizacje niosące pomoc</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje międzynarodowe udzielające pomocy ludziom</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>organizacje zajmujące się niesieniem ludziom pomocy</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>akcje charytatywne online</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>instytucje humanitarne</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>polska akcja humanitarna poznań</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc dla dzieci z biednych rodzin</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><strong><span style={{fontSize: '12pt', color: '#000000'}}>pomoc biednym na swieta</span></strong></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>organizacje niosace pomoc</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>paczka pomagam</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>organizacja niosąca ludziom pomoc</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>polska akcja humanitarna zadania</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>międzynarodowa organizacja zajmująca się udzielaniem pomocy</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>akcje na rzecz potrzebujących</strong></span></h5>
          </td>
        </tr>
        </tbody>
      </table>
      <h5><span style={{fontSize: '12pt'}}><strong>1 procent podatku dla Afryki – Przekaż darowiznę 1% podatku na Afrykę – Fundacja SaSa<br />
              </strong></span></h5>
      <h5><span style={{fontSize: '12pt'}}><strong>Afryka – wysyłamy sprzęt medyczny; Ukraina – wysyłamy wolontariat;</strong></span></h5>
      <h5><span style={{fontSize: '12pt'}}><strong>Polska i Kazachstan – organizujemy białe niedziele – profilaktyka i akcje prozdrowotne.</strong></span></h5>
      <h5><span style={{fontSize: '12pt'}}><strong>Organizacja pożytku publicznego SASA wspiera chorych w Afryce.</strong></span></h5>
      <h5><span style={{fontSize: '12pt'}}><strong>Sprzęt medyczny i wolontariat misyjny – wspieramy chorych i potrzebujących.</strong></span></h5>
      <table>
        <tbody>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu oddać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>jeden procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>przekazanie podatku na organizacje</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na co 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>oddaj 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>pit 1</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na co 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>zbiórka</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu można przekazać 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu jeden procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu mogę przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na kogo 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>dla kogo 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu można przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>epity</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na kogo oddać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu oddać 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku dla kogo</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na kogo oddac 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 komu przekazać</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku na kogo przekazać</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na co przekazac 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>zbiórka charytatywna</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku 2019</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na co przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>przekaz 1 podatku fundacja</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>krs podatek</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>krs 1 procent</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu przekazać 1 podatku lista</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>lista fundacji na które można przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>podatki gov pl</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na co dać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 procent podatku 2019</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>e pity</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>fundacje krs 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu dać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>prosze o przekazanie 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na kogo można przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>czy można przekazać 1 procent na dowolny cel</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>podatek 1 procent</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 procent podatku na stowarzyszenie</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku na stowarzyszenie</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu przekazać 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>przekazanie 1 podatku lista</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>lista opp</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na co można przekazać 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku komu przekazać</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>fundacja 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na kogo przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>przekaż jeden procent</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu 1 procent podatku lista</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>odliczenie 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>www e pit</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>przekaż 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku na kogo</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>gdzie oddać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>organizacje do przekazania 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu przekazac 1 procent</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na kogo można oddać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu 1 procent</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>krs 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>przekazanie 1 procent podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>opp lista</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu można oddać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu przeznaczyć 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 od podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>przekaż 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 podatku jak to działa</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 procent</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>na co oddać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>1 procent podatku komu</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>dla kogo przekazać 1 podatku</strong></span></h5>
          </td>
        </tr>
        <tr>
          <td>
            <h5><span style={{fontSize: '12pt'}}><strong>komu przekazac jeden procent podatku</strong></span></h5>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <p />
  </div>
)
