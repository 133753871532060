import React from "react"
import Layout from "@layout/layout"
import SEO from "@layout/seo"
import Hero from "@homepage/hero"
import PIT from "@homepage/pit"
import SasaBox from "@homepage/sasa-box"
import Voluntary from "@homepage/voluntary"
import Local from "@homepage/local"
import Help from "@homepage/help"
import Blog from "@homepage/blog"
import Meet from "@homepage/meet"
import Partners from "@homepage/partners"
import HeaderHome from "@layout/header-home"
import TableOfContents from '@homepage/table-of-contents'
import {graphql} from "gatsby";

export default ({data, isEn}) => (
  <Layout isEn={isEn}>
    <HeaderHome isEn={isEn}/>
    <SEO
      title={isEn ? 'Home - SASA Foundation | Humanitarian Aid Organizations' : 'Fundacja SASA - pomoc potrzebującym placówkom medycznym | Świat'}
      description={isEn ? 'We believe that every patient deserves a decent health care. SASA Foundation is working to improve access to basic medical supplies and routine checkups.' : 'SASA niesie pomoc osobom chorym i cierpiącym, w miejscach gdzie brakuje podstawowego sprzętu oraz personelu medycznego, bez których opieka medyczna nie może być zagwarantowana na godnym poziomie.'}
    />
    <Hero isEn={isEn}/>
    <div className="whitebg">
      {!isEn && <PIT />}
      <SasaBox isEn={isEn}/>
      <Voluntary isEn={isEn}/>
      <Local isEn={isEn}/>
      <Help isEn={isEn}/>
      <Blog posts={data.blog.posts}/>
    </div>
    <Meet isEn={isEn}/>
    <Partners isEn={isEn}/>
    <TableOfContents />
  </Layout>
)

export const pageQuery = graphql`
    query EnHomepageBlog {
        blog: allMarkdownRemark {
            posts: nodes {
                frontmatter {
                    title
                    date
                    slug
                    author
                    excerpt
                    thumbnail
                    tag
                }
                html
            }
        }
    }
`
